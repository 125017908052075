<template>
    <div>
        <div class="p-t-10">
          <div v-for="tag in tagList" :key="tag.key"  class="p-l-20 p-r-20 m-b-2"
            @click="handleChangeTag(tag.key)" :class="currentTag=== tag.key?'workplatform-radio-button-active':'workplatform-radio-button'">
            {{tag.name}}</div>
        </div>

        <Divider dashed><span class="divider-text">创建{{tagName}}</span></Divider>
        <component :is="createComponent" @on-create="handleCreate"></component>
    </div>
</template>

<script>
export default {
  components: {
    CreateContract: () => import('./components/CreateContract'),
    CreateAgreement: () => import('./components/CreateAgreement'),
    CreateContractChange: () => import('./components/CreateContractChange'),
    CreateStatementChange: () => import('./components/CreateStatementChange'),
    CreateInvoice: () => import('./components/CreateInvoice'),
    CreateStatementInvoice: () => import('./components/CreateStatementInvoice'),
    CreateStatement: () => import('./components/CreateStatement')
  },
  data () {
    return {
      createComponent: '',
      currentTag: 1,
      tagName: '',
      tagList: [
        { key: 1, name: '合同' },
        { key: 2, name: '合同变更' },
        { key: 3, name: '合同开票' },
        { key: 4, name: '确认单' },
        { key: 5, name: '结算单' },
        { key: 6, name: '结算单变更' },
        { key: 7, name: '结算单开票' }
      ]
    }
  },
  created () {
    this.handleChangeTag(this.currentTag)
  },
  methods: {
    handleChangeTag (tag) {
      this.currentTag = tag
      this.tagName = this.tagList.find(x => x.key === tag).name
      let pageType = ''

      switch (tag) {
        case 1:
          pageType = 'contract'
          this.createComponent = 'CreateContract'
          break
        case 2:
          pageType = 'contractChange'
          this.createComponent = 'CreateContractChange'
          break
        case 3:
          pageType = 'invoice'
          this.createComponent = 'CreateInvoice'
          break
        case 4:
          pageType = 'agreement'
          this.createComponent = 'CreateAgreement'
          break
        case 5:
          pageType = 'statement'
          this.createComponent = 'CreateStatement'
          break
        case 6:
          pageType = 'statementChange'
          this.createComponent = 'CreateStatementChange'
          break
        case 7:
          pageType = 'statementInvoice'
          this.createComponent = 'CreateStatementInvoice'
          break
        default:
          break
      }

      this.$emit('on-change', pageType, this.tagName)
    },
    handleCreate (baseId) {
      this.$emit('on-create', baseId)
    }
  }
}
</script>
